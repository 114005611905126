import { enviosAgrupadosService } from "@/_services";
export const enviosAgrupados = {
  namespaced: true,
  state: {
    entity: {
      data: [],
      status: {},
    },
    list: {
      data: [],
      status: {},
    },
    etiquetas: {
      data: {},
      status: {},
    },
    finalizar: {
      data: {},
      status: {},
    },
    anular: {
      data: {},
      status: {},
    },
    editarBultos: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getEnviosAgrupadosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getEnviosAgrupadosError(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getEnviosAgrupadosSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
    getEnvioAgrupadoRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getEnvioAgrupadoError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        loading: false,
        error,
      };
    },
    getEnvioAgrupadoSuccess(state, env) {
      state.entity.data = env;
      state.entity.status = {
        loaded: true,
      };
    },
    printEtiquetaRequest(state) {
      state.etiquetas.status = {
        loading: true,
      };
    },
    printEtiquetaSuccess(state, pdf) {
      state.etiquetas.data = pdf;
      state.etiquetas.status = {
        loaded: true,
      };
    },
    printEtiquetaError(state, error) {
      state.etiquetas.data = null;
      state.etiquetas.status = {
        error,
      };
    },
    anularRequest(state) {
      state.anular.status = {
        loading: true,
      };
    },
    anularSuccess(state, pdf) {
      state.anular.data = pdf;
      state.anular.status = {
        loaded: true,
      };
    },
    anularError(state, error) {
      state.anular.data = null;
      state.anular.status = {
        error,
      };
    },
    editarBultosRequest(state) {
      state.editarBultos.status = {
        loading: true,
      };
    },
    editarBultosSuccess(state, pdf) {
      state.editarBultos.data = pdf;
      state.editarBultos.status = {
        loaded: true,
      };
    },
    editarBultosError(state, error) {
      state.editarBultos.data = null;
      state.editarBultos.status = {
        error,
      };
    },
    finalizarRequest(state) {
      state.finalizar.status = {
        loading: true,
      };
    },
    finalizarSuccess(state, pdf) {
      state.finalizar.data = pdf;
      state.finalizar.status = {
        loaded: true,
      };
    },
    finalizarError(state, error) {
      state.finalizar.data = null;
      state.finalizar.status = {
        error,
      };
    },
  },
  actions: {
    getEnviosAgrupados({ commit }, req) {
      commit("getEnviosAgrupadosRequest");
      enviosAgrupadosService
        .getEnviosAgrupados(req.pagination, req.filters)
        .then((env) => {
          commit("getEnviosAgrupadosSuccess", env);
        })
        .catch((error) => {
          commit("getEnviosAgrupadosError", error);
        });
    },
    getEnvioAgrupado({ commit }, id) {
      commit("getEnvioAgrupadoRequest");
      enviosAgrupadosService
        .getById(id)
        .then((env) => {
          commit("getEnvioAgrupadoSuccess", env);
        })
        .catch((error) => {
          commit("getEnvioAgrupadoError", error);
        });
    },
    printEtiqueta({ commit }, id) {
      commit("printEtiquetaRequest");
      enviosAgrupadosService
        .imprimirEtiquetas(id)
        .then((pdf) => {
          commit("printEtiquetaSuccess", pdf);
        })
        .catch((error) => {
          commit("printEtiquetaError", error);
        });
    },
    finalizar({ commit }, body) {
      commit("finalizarRequest");
      enviosAgrupadosService
        .finalizar(body)
        .then((pdf) => {
          commit("finalizarSuccess", pdf);
        })
        .catch((error) => {
          commit("finalizarError", error);
        });
    },
    anular({ commit }, body) {
      commit("anularRequest");
      enviosAgrupadosService
        .anular(body)
        .then((pdf) => {
          commit("anularSuccess", pdf);
        })
        .catch((error) => {
          commit("anularError", error);
        });
    },
    editarBultos({ commit }, req) {
      commit("editarBultosRequest");
      enviosAgrupadosService
        .editarBultos(req.id, req.body)
        .then((pdf) => {
          commit("editarBultosSuccess", pdf);
        })
        .catch((error) => {
          commit("editarBultosError", error);
        });
    },
  },
};
